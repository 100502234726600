<template>
    <div class="main-content">
      <section class="news-section">
        
        <h1 style="margin-bottom: 20px;">{{this.$route.params.categoryName}}</h1>
        <b-row v-for="post in posts"  :key="post.id" class="article">
          <b-col cols="5">
            <img :src="post.post_image" :alt="post.title" class="news-image" />
          </b-col>
          <b-col cols="7">
            <div class="news-content">
                <span class="category-url">
                    <span v-if="post.author && post.author.name">
                      <router-link 
                          :to="{ 
                           name: 'author-articles', 
                           params: { authorName: post.author.name.split(' ').join('-') }, 
                           query: { id: post.author.id } 
                            }" 
                            class="highlight">
                           {{ post.author.name }}
                         </router-link>
                     </span>
                   <span v-else> Unknown Author</span>
                </span>
            
              <h4 class="titleUrl" v-if="post.category && post.category.category"><router-link 
                :to="{ name: 'blog-article', params: { category: post.category.category.replace(/\s+/g, ''), date: new Date(post.posted_at).toISOString().split('T')[0], slug: post.slug }, query: { id: post.id } }"
                >{{ post.title }}</router-link></h4>
              <p class="category-url">{{ post.posted_at }}</p>
            </div>
          </b-col>
        </b-row>
       
      </section>
     
      <SidebarComponent />
    </div>
  </template>
  
      
      <script>
      import SidebarComponent from '@/components/SideBar.vue'
      import {checkAuth} from '@/utils/auth.js'
      export default {
        name: "CategoryArticle",
        components: {
          SidebarComponent
          
        },
        props: ['categoryName','id'], // These are passed from the route
        data() {
          return {
            posts: []
          };
        },
        methods:{
          
            getCategoryPosts(authorId){
              this.$axios.get('/api/category/'+authorId+'/posts').then((response) => {
                this.posts = response.data.posts;
                console.log(response.data)
          }).catch(error => {
              console.log(error); // Handle error (e.g., credentials didn't match)
          });
            },
            truncateText(text, length) {
                return text.length > length ? text.substring(0, length) + '...' : text;
                },
        },
        created() {   
          this.getCategoryPosts(this.$route.query.id) 
          checkAuth()
                .then(user => {
                    this.user = user;
                })
                .catch(error => {
                    console.error('Error checking auth:', error);
                });
      },
      watch: {
        '$route'() {
          this.getCategoryPosts(this.$route.query.id) 
        }
      }
      };
      </script>
      <style>
      .news-section {
    width: 65%;
    margin: 0 auto;
  }
  
  .article {
     
      margin-bottom: 20px;
      overflow: hidden;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
  }
  
  .single-article {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
  }
  .category-url a{
    text-decoration: none;
    font-size: 15px;
    color: #a7aab2 !important;
  }

  .titleUrl a{
    text-decoration: none;
   
  }

  .category-url a:hover{
    text-decoration: none;
    font-size: 15px;
    color: #0f0f10 !important;
  }
  .news-image {
    width: 250px; /* Ensures the image fits within the column */
    height: 120px;
    max-height: 480px;
    object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  }
  
  .single-article h1 {
    margin-bottom: 10px;
    color: #d30e15;
    font-family: monospace;
    margin: 0 0 30px;
    font-size: 3.0rem;
    line-height: 40px;
  }
  
  .single-article p {
    font-size: 17px;
    color: #040404;
    line-height: 1.6;
  }
  
  .description {
    text-align: justify;
  }
    
    
     </style>
      