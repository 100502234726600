<template>
    <section class="news-section">
      <h2 class="category-header">Most Popular Posts</h2>

  <div class="grid">
    <article v-for="article in articles" :key="article.id" class="grid-article-categories">
      <img loading="lazy"  :src="article.post_image" alt="news image" />
      <div class="content">
       
        <router-link  class="title"
                :to="{ name: 'blog-article', params: { category: article.category.category.replace(/\s+/g, ''), date: new Date(article.posted_at).toISOString().split('T')[0], slug: article.slug }, query: { id: article.id } }">{{ article.title }}</router-link>
        <p class="time">{{ article.posted_at }}</p>
      </div>
    </article>
  </div>
</section>
</template>



<script>
export default {
  name: "NewsCategory",
  data() {
    return {
      articles: [
        ],
    };
  },
  methods:{
    getPopularPosts(){
      this.$axios.get('/api/popularPosts').then((response) =>{
       this.articles = response.data.posts
      })
    }
  },
  created(){
    this.getPopularPosts()
  }
};
</script>

<style scoped>
.news-section {
width: 80%;
margin: 0 61px;
}



.grid {
display: flex;
flex-wrap: wrap;
gap: 20px;
}

.time{
  font-size: 0.7em !important;
}

.grid-article-categories {
flex: 1 1 calc(10% - 10px); /* Two columns */
display: flex;
flex-direction: column;
border-radius: 10px;
overflow: hidden;
background-color: #fff;
transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-article:hover {
transform: translateY(-5px);
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}



.grid-article-categories img {
  width: 300px;
  height: 163px !important;
height: auto;
object-fit: cover;
transition: transform 0.5s ease-in-out;
}

.grid-article .content {
padding: 15px;
}

.category-header{
  padding-bottom: 10px;
}
.grid-article h3 {
font-size: 1.5em;
margin-bottom: 10px;
color: #333;
}

.grid-article p {
font-size: 1em;
color: #666;
line-height: 1.6;
}



.title{
    font-family: 'Cormorant', serif;
    font-size: 20px;
    text-decoration: none;
    color: black;
}

</style>
