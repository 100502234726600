import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import UserLogin from '@/components/UserLogin.vue';
import BlogArticle from '@/components/BlogArticle.vue'
import AuthorArticle from '@/components/AuthorArticles.vue'
import CategoryArticle from '@/components/CategoryArticles.vue'
import Profilepage from '@/components/ProfilePage.vue'
import SettingsPage from '@/components/SettingsPage.vue'
import TagArticle from '@/components/TagArticles.vue'

const routes = [
  { path: '/', component: HomePage },
  { path: '/login', component: UserLogin },
  {
    path: '/:category/:date/:slug',
    name: 'blog-article',
    component: BlogArticle,
    props: (route) => ({ id: route.query.id }
  
    ) 
    // Pass route params as props to the component
  },
  {
    path: '/author/:authorName',
    name: 'author-articles',
    component: AuthorArticle,
    props: (route) => ({ id: route.query.id }
  
    ) 
    // Pass route params as props to the component
  },
  {
    path: '/category/:categoryName',
    name: 'post-categories',
    component: CategoryArticle,
    props: (route) => ({ id: route.query.id }
  
    ) 
    // Pass route params as props to the component
  },
  {
    path: '/my-profile',name:'my-profile',component: Profilepage
  },
  {
    path: '/settings',name:'settings',component: SettingsPage
  },
  {
    path: '/tag/:tag',
    name: 'tag-posts',
    component: TagArticle,
    props: (route) => ({tag:route.query.tag})
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
