<template>
    <div class="loginsignupform">
       
    <b-modal v-model="isModalVisible" @hide="closeModal" >
      <b-tabs  content-class="mt-3" v-model="currentTab">
        <b-tab title="Sign In">
            <b-container class="d-flex justify-content-center align-items-center mt-5">
    <b-card
      style="max-width: 30rem; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"
      class="p-4"
      bg-variant="light"
    >
      <h3 class="text-center mb-4">Sign In</h3>
      <b-form @submit.prevent="login()">
        <!-- Username Input -->
        <b-form-group label="Email" label-for="input-1" label-cols-sm="4">
          <b-input-group prepend-icon="person">
            <b-form-input
              id="input-1"
              v-model="form.email"
              required
             
            />
          </b-input-group>
        </b-form-group>

        <!-- Password Input -->
        <b-form-group label="Password" label-for="input-2" label-cols-sm="4">
          <b-input-group prepend-icon="lock">
            <b-form-input
              id="input-2"
              type="password"
              v-model="form.password"
              required
             
            />
          </b-input-group>
        </b-form-group>

        <!-- Submit Button -->
        <div class="mt-4 d-grid">
          <b-button type="submit" variant="primary" block class="btn-lg">Login</b-button>
        </div>
      </b-form>
    </b-card>
  </b-container>
        </b-tab>
        <b-tab title="Sign Up">
          <b-card
      style="max-width: 30rem; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"
      class="p-4"
      bg-variant="light"
    >
      <h3 class="text-center mb-4">Sign Up</h3>
      <b-form v-on:submit="signup()">
  <!-- Username Input -->
              <b-form-group label="Username" label-for="input-1" label-cols-sm="4">
                  <b-input-group prepend-icon="person">
                    <b-form-input
                    id="input-1"
                    v-model="signupForm.name"
                    required
                    
                   />
                  </b-input-group>
              </b-form-group>

  <!-- Email Input -->
              <b-form-group label="Email" label-for="input-2" label-cols-sm="4">
                 <b-input-group prepend-icon="person">
                     <b-form-input
                        id="input-2"
                        v-model="signupForm.email"
                        required
                       
                      />
                  </b-input-group>
              </b-form-group>

  <!-- Password Input -->
              <b-form-group label="Password" label-for="input-3" label-cols-sm="4">
                     <b-input-group prepend-icon="lock">
                         <b-form-input
                            id="input-3"
                            type="password"
                            v-model="signupForm.password"
                            required
                           
                       />
                      </b-input-group>
              </b-form-group>

  <!-- Password Confirmation Input -->
  <b-form-group label="Password Confirmation" label-for="input-4" label-cols-sm="4">
    <b-input-group prepend-icon="lock">
      <b-form-input
        id="input-4"
        type="password"
        v-model="signupForm.password_confirmation"
        required
       
      />
    </b-input-group>
  </b-form-group>

  <!-- Logo Upload Input -->
  <b-form-group label="Logo" label-for="input-5" label-cols-sm="4">
    <input class="form-control" type="file" id="avatar"  @change="(event) => handelFileUpload(event)" name="avatar" accept="image/png, image/jpeg" />
  </b-form-group>

  <!-- Submit Button -->
  <div class="mt-4 d-grid">
    <b-button type="submit" variant="primary" block class="btn-lg">Sign Up</b-button>
  </div>
</b-form>

    </b-card>
        </b-tab>
      </b-tabs>
    </b-modal>
</div>
  </template>
  
  <script>
  import {userStore} from '@/pinia/store.js'
  export default {
    name: 'AuthModal',
    data(){
        return {
        isModalVisible: false,
        form:{
            email:'',
            password:''
        },
        signupForm:{
            name:'',
            email:'',
            password:'',
            password_confirmation:'',
            picture:''
        }
        ,
        currentTab:0,
        UserStorage:userStore()
      };
    },
    methods: {
        showModal() {
            console.log('This is calling')
        this.isModalVisible = true;
        console.log(this.isModalVisible)
      },
      closeModal() {
        this.isModalVisible = false;
      },
      async login() {
        try{
          await  this.UserStorage.login(this.form)
           this.UserStorage.loadState()
           console.log('Loggin succesfull')
          if (this.UserStorage.user.loggedIn) {
              this.isModalVisible = false; // Hide modal on successful login
            }

        }
        catch(err){
          
          console.log(err)
          
          
        }
       
         },
         signup(){
         
          let data = new FormData();
          data.append('name', this.signupForm.name);
          data.append('email', this.signupForm.email);
          data.append('password', this.signupForm.password);
          data.append('password_confirmation', this.signupForm.password);
          data.append('picture', this.signupForm.picture); 
          this.$axios.post('/api/register',data).then((response) =>{
                console.log(response.data)
          }).catch((error) =>{
            console.log(error)
          })
          this.currentTab = 0
         },
         handelFileUpload(e){
          this.signupForm.picture=e.target.files[0]
         }
         
    }
  };
  </script>
  
  <style scoped>
  .btn-lg{
    background-color: #0f9d58;
  }
  /* Add any specific styling for the modal if needed */
  </style>
  