<template>
    <header>
      <nav>
        <ul>
          <li><a href="/">Home</a></li>
          <li v-for="category in categories" :key="category.id">
            <router-link 
                      :to="{ 
                      name: 'post-categories', 
                      params: { categoryName: category.category.split(' ').join('-') }, 
                      query: { id: category.id } 
                    }" 
                  class="highlight"   
                  @click.prevent="reloadRoute(category)">
                 {{ category.category }}
                </router-link>
           
          </li>
          <li v-if="userDetails.user.loggedIn">
            <div>
            <a href="#" @click="showDropDown=!showDropDown">
                <img :src="userDetails.user.logo" class="profile-photo" alt="avatar">
                 <!-- <i :class="{ 'fa-caret-up': showDropDown, 'fa-caret-down': !showDropDown }" class="fa" aria-hidden="true"></i> -->
            </a>
           <div>
                <ul v-if="showDropDown" class="dropdown-content">
                    <li >
                     <router-link :to="{name:'my-profile'}" >Profile</router-link>
                     <router-link :to="{name:'settings'}">Settings</router-link>
                     <a href="javascript:void(0)"  @click="logout" class="dd-link pointer hover-bg-moon-gray">Logout</a>
                    </li>
               </ul>
            </div>
         </div>
           
          </li>
               
        </ul>
      </nav>
    </header>
  </template>
  
  <script>
  import {userStore} from '@/pinia/store.js'
  export default {
   
    name: "HeaderComponent",
    data(){
      return {
        categories:[],
        userDetails:userStore(),
        showDropDown:false
        
      }
    },
    methods:{
     
      logout(){
        this.userDetails.logout()
        this.showDropDown = false
      },
      getCategories(){
        this.$axios.get('/api/categories').then((response) => {
          this.categories = response.data.categories;
         
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
      },
      reloadRoute(category) {
        console.log(category.category)
            this.$router.push({ 
               name: 'post-categories', 
               params: { 
                   categoryName: category.category.split(' ').join('-')
                  }, 
                query: { id: category.id } 
            });
            } 
    },
    created(){
      this.showDropDown = false
      this.getCategories()
      this.userDetails.loadState()
     
      
    }
  };
  </script>
  
  <style scoped>
  
  header {
    background-color: #d30e15;
    padding: 10px 0;
    position: fixed; /* Makes the header fixed at the top */
    top: 0;
    width: 100%; /* Ensures the header spans the full width of the page */
    z-index: 1000; /* Places the header above other content */
  }
  nav ul {
    display: flex;
    list-style: none;
    justify-content: center;
  }
  nav ul li {
    margin: 0 15px;
  }
  nav ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 13px;
  }
  .dropdown-content {
  position: absolute;
  background-color: #ffffff;
  min-width: 184px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  right: 42px; /* Move the dropdown 10px to the left */
}

.dropdown-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-content li {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.profile-photo {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
}

  </style>
  