<template>
    <footer>
      <p>&copy; 2024 News Blog. All rights reserved.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: "FooterComponent",
  };
  </script>
  
  <style scoped>
  footer {
    background-color: #333;
    padding: 20px;
    text-align: center;
    color: #fff;
  }
  </style>
  