<template>
   <b-container class="d-flex justify-content-center align-items-center vh-100">
    
    <b-form @submit.prevent="handleSubmit" class="w-50 form-profile">
      <input type="hidden" v-model="form.id" />
      <!-- Avatar Upload -->
      <b-form-group label="Avatar" label-for="avatar" >
        <img class="avatar" :src="form.avatar" alt="profile image" data-role="avatar-img">
        <input class="ml-5px" type="file" id="avatar"  @change="(event) => onFileChange(event)" name="avatar" accept="image/png, image/jpeg" />
        
      </b-form-group>

      <!-- Name Field -->
      <b-form-group label="Name" label-for="name">
        <b-form-input
          id="name"
          v-model="form.name"
          :state="validateName"
          required
        ></b-form-input>
       
      </b-form-group>

      <!-- Biography Field -->
      <b-form-group label="Biography" label-for="biography">
        <b-form-textarea
          id="biography"
          v-model="form.biography"
          rows="3"
        ></b-form-textarea>
      </b-form-group>

      <!-- Website Field -->
      <b-form-group label="Website" label-for="website">
        <b-form-input
          id="website"
          v-model="form.website"
          type="url"
        ></b-form-input>
      </b-form-group>

      <!-- Private Profile Field -->
      <b-form-group>
        <b-form-checkbox v-model="form.isPrivate" >
          Keep your profile activity private
        </b-form-checkbox>
      </b-form-group>

      <!-- Submit Button -->
      <b-button type="submit" variant="primary">Save</b-button>
    </b-form>
  </b-container>
</template>

<script>
import { userStore } from '@/pinia/store';
export default {
  data() {
    return {
      form: {
        id:'',
        avatar: '', // Initialize as an empty string or file object
        name: '',
        biography: '',
        website: '',
        isPrivate: true, // Default to private
      },
      userDetails:userStore()
    };
  },
 
  created(){
    if(this.userDetails.user.loggedIn){
      this.refreshForm()
    }

    else{
      this.$router.push('/')
    }
    
     
  },
  methods: {
    refreshForm(){
      this.form.id=this.userDetails.user.id
      this.form.avatar=this.userDetails.user.logo
      this.form.name=this.userDetails.user.name
      this.form.biography=this.userDetails.user.biography
      this.form.website=this.userDetails.user.website
      this.form.isPrivate=this.userDetails.user.isprivate
    },
    onFileChange(event) {
      // Handle file upload event
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        this.form.avatar = file; // Set the avatar to the uploaded file
      } else {
        this.form.avatar = ''; // Reset if no file is selected
      }
      console.log(this.form.avatar)
    },
    async handleSubmit() {
      let fd = new FormData()
      let avatar = (typeof this.form.avatar == 'string') ? '' : this.form.avatar
      fd.append('id',this.form.id)
      fd.append('avatar',avatar)
      fd.append('name',this.form.name)
      fd.append('biography',this.form.biography)
      fd.append('website',this.form.website)
      fd.append('isPrivate',this.form.isPrivate)
      await this.userDetails.saveUserData(fd)
      this.refreshForm()
    },
  },
};
</script>

<style scoped>

.form-profile{
  margin-top: 25px;
}
.avatar {
  width: 6.5rem;
  height: 6.5rem;
  cursor: pointer;
}
.ml-5px {
  margin-left: 25px;
}
/* You can add custom styles here */
</style>
  