<template>
  <div class="main-content">
    <section class="news-section">
      <div  v-if="UserDetails.user.loggedIn">
        <b-button v-if ="is_subscribed" @click="SubscribeAuthor(false)" variant="danger">UnSUBSCRIBE AUTHOR</b-button>
        <b-button  v-else  @click="SubscribeAuthor(true)" variant="danger">SUBSCRIBE AUTHOR</b-button>
        
    </div>
    <div v-else>
            <p>Please <a href="javascript:void(0)" @click="toggleModal">log in</a> to Subscribe.</p>
      </div>
      <AuthModal ref="authComponent" />
      
      <p>ALL ARTICLES BY:</p>
      <h1 style="margin-bottom: 20px;">{{this.$route.params.authorName}}</h1>
      <b-row v-for="post in posts"  :key="post.id" class="article">
        <b-col cols="5">
          <img :src="post.post_image" :alt="post.title" class="news-image" />
        </b-col>
        <b-col cols="7">
          <div class="news-content">
            <span class="category-url">
             <span v-if="post.category && post.category.category">
                <router-link 
                      :to="{ 
                      name: 'post-categories', 
                      params: { categoryName: post.category.category.split(' ').join('-') }, 
                      query: { id: post.category.id } 
                    }" 
                  class="highlight">
                 {{ post.category.category }}
                </router-link>
            </span>
          <span v-else> Unknown Author</span>
         </span>
         <h4 class="titleUrl" v-if="post.category && post.category.category"><router-link 
                :to="{ name: 'blog-article', params: { category: post.category.category.replace(/\s+/g, ''), date: new Date(post.posted_at).toISOString().split('T')[0], slug: post.slug }, query: { id: post.id } }"
                >{{ post.title }}</router-link></h4>
            <p class="category-url">{{ post.posted_at }}</p>
             <div class="tags" v-if="post.tags">
                 
                 <router-link  v-for="(tag, index) in post.tags" :key="index"
                :to="{ name: 'tag-posts', params: { tag: tag.toLowerCase() }}"
                >{{ tag }}</router-link>
             </div>
          </div>
        </b-col>
      </b-row>
    </section>
    <SidebarComponent />
  </div>
</template>

    
    <script>
    import SidebarComponent from '@/components/SideBar.vue'
    import {userStore} from '@/pinia/store.js'
    import AuthModal from './AuthModal.vue'

    export default {
      name: "AuthorArticle",
      components: {
        SidebarComponent,
        AuthModal
        
      },
      props: ['authorName','id'], // These are passed from the route
      data() {
        return {
          posts: [],
          UserDetails: userStore(),
          is_subscribed: false,
        };
      },
      methods:{
          getAuthorPosts(authorId){
            this.$axios.get('/api/author/'+authorId+'/posts').then((response) => {
              this.posts = response.data.posts;
              console.log(response.data)
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
          },
          SubscribeAuthor(status){
             
              this.$axios.post('/api/subscribe',{'author_id':this.$route.query.id,'is_subscribed':status}).then((response) =>{
                console.log(response.data)
                this.is_subscribed = status
              })
          },
          hasSubscribedAuthor(){
            this.$axios.get(`/api/hasSubscribed/${this.$route.query.id}`).then((response) =>{
                   this.is_subscribed = response.data.status
              })
          },
          truncateText(text, length) {
              return text.length > length ? text.substring(0, length) + '...' : text;
              },

              toggleModal() {
          this.$refs.authComponent.showModal();
    },
      },
      created() {   
        this.getAuthorPosts(this.$route.query.id) 
        if(this.UserDetails.user.loggedIn){
          this.hasSubscribedAuthor()
         }
    },
    watch: {
        
        'UserDetails.user.loggedIn'(newValue, oldValue) {
               if (newValue) {
                 this.hasSubscribedAuthor()
            } 
            else{
              console.log(oldValue)
            }
          },
       }
    };
    </script>
    <style>
    .news-section {
  width: 65%;
  margin: 0 auto;
}

.article {
   
    margin-bottom: 20px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.single-article {
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
}
.category-url{
  text-decoration: none;
  font-size: 15px;
  color: #a7aab2 !important;
}
.news-image {
  width: 250px; /* Ensures the image fits within the column */
  height: 120px;
  max-height: 480px;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
}

.single-article h1 {
  margin-bottom: 10px;
  color: #d30e15;
  font-family: monospace;
  margin: 0 0 30px;
  font-size: 3.0rem;
  line-height: 40px;
}

.single-article p {
  font-size: 17px;
  color: #040404;
  line-height: 1.6;
}

.description {
  text-align: justify;
}
  
.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between tags as needed */
}

.tags a {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Adjust the border-radius as needed */
    background-color: #f9f9f9; /* Adjust the background color as needed */
    text-decoration: none;
    color: #333;
    font-size: 10px;
    font-weight: 700;
}

.tags a:hover {
    background-color: #e9e9e9; /* Adjust the hover background color as needed */
}
  
   </style>
    