<template>
    <b-container class="d-flex justify-content-center align-items-center vh-100">
     
     <b-form @submit.prevent="handleSubmit" class="w-50 form-profile">
       <input type="hidden" v-model="form.id" />
       <!-- Avatar Upload -->
       
 
       <!-- Name Field -->
       <b-form-group label="Old Password" label-for="old_password">
         <b-form-input
           id="old_password"
           type="password"
           v-model="form.old_password"
           required
         ></b-form-input>
        
       </b-form-group>
 
       <!-- Biography Field -->
       <b-form-group label="New Password" label-for="new_password">
         <b-form-input
           id="new_password"
           type="password"
           v-model="form.new_password"
           required
         ></b-form-input>
        
       </b-form-group>
 
       <!-- change password Field -->
       <b-form-group label="Confirm Password" label-for="confirm_password">
         <b-form-input
           id="confirm_password"
           type="password"
           v-model="form.new_password_confirmation"
           required
         ></b-form-input>
        
       </b-form-group>
 
      
 
       <!-- Submit Button -->
       <b-button type="submit" variant="primary">Save</b-button>
     </b-form>
   </b-container>
 </template>
 
 <script>
 import { userStore } from '@/pinia/store';
 export default {
   data() {
     return {
       form: {
        old_password:'',
        new_password: '', 
        new_password_confirmation: ''
       },
       userDetails:userStore()
     };
   },
  
   created(){
    //  if(this.userDetails.user.loggedIn){
    //    this.refreshForm()
    //  }
 
    //  else{
    //    this.$router.push('/')
    //  }
     
      
   },
   methods: {
     refreshForm(){
       this.form.id=this.userDetails.user.id
       this.form.old_password = ''
       this.form.new_password = ''
      this.form.new_password_confirmation = ''
       
     },
    
     async handleSubmit() {
      
       await this.userDetails.changePassword(this.form)
       this.refreshForm()
     },
   },
 };
 </script>
 
 <style scoped>
 
 .form-profile{
   margin-top: 25px;
 }
 .avatar {
   width: 6.5rem;
   height: 6.5rem;
   cursor: pointer;
 }
 .ml-5px {
   margin-left: 25px;
 }
 /* You can add custom styles here */
 </style>
 