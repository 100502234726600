import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import { BootstrapVue3,BModal } from 'bootstrap-vue-3';
import { createPinia } from 'pinia';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.defaults.baseURL = 'https://main.blogocean.xyz.lk';

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.use(router); // Add the router here
app.use(BModal)
app.use(BootstrapVue3)


app.use(createPinia())


app.mount('#app');

