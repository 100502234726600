<template>
      <section class="news-section">
    <article class="featured-article" v-if="posts.length">
      <img :src="posts[0].post_image" alt="Featured news image" />
      <div class="content">
        <h2>{{ posts[0].title }}</h2>
        <p v-html="truncateText(posts[0].description,350)"></p>
        <h6>{{ posts[0].author.name }}</h6>
        <p class="time">{{ posts[0].posted_at }}</p>
      </div>

    </article>

    <div class="grid">
      <article v-for="post in posts.slice(1)" :key="post.id" class="grid-article">
        <img :src="post.post_image" alt="news image" />
        <div class="content">
          <h3>{{ post.title }}</h3>
          <p v-html="truncateText(post.description, 150)"> </p>
          <h6>{{ post.author.name }}</h6>
          <p class="time">{{ post.posted_at }}</p>
        </div>
      </article>
    </div>
  </section>
</template>

 
 
  <script>
  export default {
    name: "NewsSection",
    data() {
      return {
        posts: [],
      };
    },

    methods:{
      getlatestposts(){
        this.$axios.get('/api/getLandingPageMainPosts').then((response) => {
          this.posts = response.data.posts;
          console.log(response.data)
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
      },
      truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    }
    },
    
     created(){
      this.getlatestposts();
     }
  };
  </script>
  
  <style scoped>
  .news-section {
  width: 65%;
  margin: 0 auto;
}

.featured-article {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;

}

.featured-article img {
  width: 100%;
  height: auto;
  max-height: 539px;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
 /* object-position: center; Centers the image within the container */
}

.featured-article .content {
  padding: 20px;
}

.featured-article h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.featured-article p {
  font-size: 1.2em;
  color: #666;
  line-height: 1.6;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.time{
    font-size: 0.9em !important;
}

.grid-article {
  flex: 1 1 calc(50% - 10px); /* Two columns */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.grid-article:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.grid-article img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.grid-article .content {
  padding: 15px;
}

.grid-article h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.grid-article p {
  font-size: 1em;
  color: #666;
  line-height: 1.6;
}

  </style>
  