// store.js file
import { defineStore, createPinia } from 'pinia';
import axios from "axios"
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
const pinia = createPinia()


export const userStore = defineStore({
  id: 'userDetails',
  state: () => ({
    user: {
      id:null,
      loggedIn:false,
      name:null,
      logo:null,
      email: null,
      biography: null,
      website: null,
      isprivate: false
    },
  }),
  actions: {
   async login(credentials) {
      try {
        // Get CSRF token
        await axios.get('/sanctum/csrf-cookie');
        
        // Send login request
        await axios.post('/api/login', credentials);
    
        // Get authenticated user data
        
        await this.getUserDetails()

        this.saveState()
    
      } catch (error) {
        const errorMessage = error.response.data.message
        toast(errorMessage, {
           variant: "danger",
          autoClose: 1000,
      });  
        
        console.log(error); // Handle error (e.g., credentials didn't match)
      }
    },

    async saveUserData(data){
       try{
        await axios.post('/api/updateUser',data)
        await this.getUserDetails()
        this.saveState()
       }
       catch(err){
        console.log(err)
       }
    },

    async changePassword(data){
      try{
      await axios.post('/api/changePassword',data)
      }
      catch(err){
        console.log(err)
      }
    },

    async getUserDetails(){
        const response = await axios.get('/api/user')
        const userData = response.data
        this.user.id = userData.id;
        this.user.name = userData.name;
        this.user.loggedIn = true;
        this.user.logo = userData.picture
        this.user.email = userData.email
        this.user.biography = userData.biography
        this.user.website = userData.website
        this.user.isprivate = userData.is_private

    },

    loadState(){
      const savedState = localStorage.getItem('userStore')
      if(savedState){
        this.user = JSON.parse(savedState)
      }
    },

    saveState(){
      if(localStorage.getItem('userStore')){
        localStorage.removeItem("userStore");

      }
      localStorage.setItem('userStore',JSON.stringify(this.user))
    },

    setUserDetailsToNull(){
        this.user.id  = null
        this.user.loggedIn = false
        this.user.email = null
        this.user.logo = null
        this.user.name  = null
        this.user.biography  = null
        this.user.website  = null
        this.user.isprivate  = false
    },

    async logout(){
        try{
          await axios.delete('/api/logout')
          this.setUserDetailsToNull()
          localStorage.removeItem('userStore')

        }
        catch(err){
            console.log(err)
        }
    }
  },
});
export default pinia;