<template>
    <aside class="sidebar">
      
  
      <h3>Latest News</h3>
      <ul>
        <li v-for="recentPost in recentPosts" :key="recentPost.id">
          <router-link 
                :to="{ name: 'blog-article', params: { category: recentPost.category.category.replace(/\s+/g, ''), date: new Date(recentPost.posted_at).toISOString().split('T')[0], slug: recentPost.slug }, query: { id: recentPost.id } }"
                 @click.prevent="reloadRoute(recentPost)">
                <img :src="recentPost.post_image" :alt="recentPost.title" class="news-image" />
                <div class="news-content">
                <h4>{{ recentPost.title }}</h4>
                <p v-html="truncateText(recentPost.description,75)"></p>
                </div>
           </router-link>
      </li>
      </ul>
    </aside>
  </template>
  
  <script>
  export default {
    name: "SidebarComponent",
    data() {
      return {
        recentPosts: [],
      };
    },
    methods:{
      getRecentPosts(){
        this.$axios.get('/api/getRandomPosts').then((response) => {
          this.recentPosts = response.data.posts;
          console.log(response.data)
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
      },
      truncateText(text, length) {
              return text.length > length ? text.substring(0, length) + '...' : text;
              },
      reloadRoute(recentPost) {
            this.$router.push({ 
               name: 'blog-article', 
               params: { 
                   category: recentPost.category.category.replace(/\s+/g, ''), 
                   date: new Date(recentPost.posted_at).toISOString().split('T')[0], 
                   slug: recentPost.slug 
                  }, 
                query: { id: recentPost.id } 
            });
            }  
    },
    created(){
      this.getRecentPosts();
     }
     
  };
  </script>
  
  <style scoped>
  .sidebar {
    width: 27%;
    padding: 20px;
    background-color: #fff;
    
  }
  
  .sidebar h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #444;
  }
  
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 20px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .sidebar ul li a:hover {
    color: #007bff;
  }
  
  .sidebar ul li a .news-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 10px;
  }
  
  .sidebar ul li a .news-content {
    flex: 1;
  }
  
  .sidebar ul li a .news-content h4 {
    margin: 0;
    font-size: 1.2em;
    color: #333;
  }
  
  .sidebar ul li a .news-content p {
    margin: 5px 0 0;
    font-size: 0.9em;
    color: #666;
  }
  </style>
  