<template>
  <div class="main-content">
    <ShareIcons :url="currentUrl" :title="post.slug" />
    <section class="news-section">
    <article class="single-article" >
      <h1>{{ post.title }}</h1>
      <div class="meta">
				<span class="author">
  Posted By 
  <span v-if="post.author && post.author.name">
    <router-link 
      :to="{ 
        name: 'author-articles', 
        params: { authorName: post.author.name.split(' ').join('-') }, 
        query: { id: post.author.id } 
      }" 
      class="highlight">
      {{ post.author.name }}
    </router-link>
  </span>
  <span v-else>
    Unknown Author
  </span>
</span>
				<span class="date">{{ post.posted_at }}</span>
				<span class="post-categories">
          <span v-if="post.category && post.category.category">
              <router-link 
                  :to="{ 
                   name: 'post-categories', 
                   params: { categoryName: post.category.category.split(' ').join('-') }, 
                  query: { id: post.category.id } 
                  }" 
                  class="highlight">
                 {{ post.category.category }}
                </router-link>
            </span>
          <span v-else> Unknown Author</span>
         </span>

				<span class="comments-wrapper">
              <a class="comments" href="#" @click="scrollMeTo('commentSection')"><i class="fa fa-comment" aria-hidden="true"></i> {{ comments.length }} 
              </a>
            </span>

            <span class="comments-wrapper">
              <a class="comments" href="#" ><i class="fa fa-heart" aria-hidden="true"></i>
                {{ likes.length }}</a>
            </span>

			</div>
      
      <img :src="post.post_image" alt="main Image" />
      <div v-html="post.description" class="content description">
         
      </div>

    </article>
    <div>
    <div  v-if="UserDetails.user.loggedIn">
      <div>
        <button class="likeButton" :style="[hasLiked ? { backgroundColor: 'red' } : { backgroundColor: 'blue' }]" @click="likePost">
  <i class="fa fa-thumbs-up" aria-hidden="true"></i>  {{ hasLiked ? 'Dislike' : 'Like' }}
</button>

      
     </div>
    <form @submit.prevent="submitForm" class="comment-form mb-6">
    <div class="comment-container py-2 px-4 mb-4">
        
        <textarea v-model="comment" id="comment" rows="6" class="comment-textarea" placeholder="Write a comment..." required></textarea>
    </div>
    <button type="submit" class="comment-submit-button">
        Post comment
    </button>
</form>
    </div>
    <div v-else>
            <p>Please <a href="javascript:void(0)" @click="toggleModal">log in</a> to post a comment or Like.</p>
      </div>
</div>
<div ref="commentSection" class="comments">
  <span class="section-title">COMMENTS</span>
  <div  v-for="comment in comments" class="comment-item" :key="comment.id">
    <div class="comment-header">
        <img :src="comment.commentor.picture" alt="Commenter's Avatar" class="comment-avatar" />
        <div class="comment-info">
             <p class="comment-username">{{ comment.commentor.name }} Says:</p>
               <p class="comment-date">{{ comment.created_at }}</p>
           </div>
    </div>
    <p class="comment-text">{{ comment.comment }}</p>
</div>


</div>
<div>
   <AuthModal ref="authComponent" @UserEvent="enableUser" />
  
</div>
    </section>
        <SidebarComponent />
      </div>
      
    
  </template>
  
  <script>
  import SidebarComponent from '@/components/SideBar.vue'
  import {checkAuth} from '@/utils/auth.js'
  import AuthModal from '@/components/AuthModal.vue'
  import {userStore} from '@/pinia/store.js'
  import ShareIcons from '@/components/ShareIcons.vue'
  
  export default {
    name: "BlogArticle",
    components: {
      SidebarComponent,
      AuthModal,
      ShareIcons
      
    },
    props: ['category', 'date', 'slug','id'], // These are passed from the route
    data() {
      return {
        post: {},
        UserDetails: userStore(),
        comment:'',
        comments:[],
        likes:[],
        hasLiked:false,
        currentUrl:''
      };
    },
    methods:{
         likePost(){
             this.$axios.post('/api/likeThePost/'+this.$route.query.id,{'isLiked':!this.hasLiked}).then((response)=>{
                      console.log(response.data)
                      this.hasLikedThePost()
                      this.getLikes()
                      
             })
         },
         increaseViewCount(){
            this.$axios.post('/api/blogPosts/'+this.$route.query.id+'/incrementView').then((response)=>{
              console.log(response.data)
            })
         },
        getPost(){
          this.$axios.get('/api/getPost/'+this.$route.query.id).then((response) => {
          this.post = response.data.post;
         
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
        },

        scrollMeTo(refName) {
          const element = this.$refs[refName];
          if (element) {
               element.scrollIntoView({
                     behavior: 'smooth', // Enables smooth scrolling
                      block: 'start', // Aligns the element to the top of the viewport
                       });
                } else {
                    console.warn(`No element found with ref: ${refName}`);
                  }
          },
        submitForm(){
          this.$axios.post('/api/addComment', {
          comment: this.comment,
           postId: this.$route.query.id
         }).then((response) => {
          console.log(response.data)
          this.comment = ''
          this.getcomments();
         
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
        },
        hasLikedThePost(){
            this.$axios.get('/api/hasLiked/'+this.$route.query.id).then((response) =>{
                this.hasLiked = response.data.liked
            })
        },
        getcomments(){
          
          this.$axios.get('/api/post/'+this.$route.query.id+"/comments").then((response) => {
          this.comments = response.data.comments;
         
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
        
        },
        getLikes(){
          
          this.$axios.get('/api/post/'+this.$route.query.id+"/likes").then((response) => {
          this.likes = response.data.likes;
         
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
        
        },
        toggleModal() {
          this.$refs.authComponent.showModal();
         },
         enableUser(){
          checkAuth()
            .then(user => {
                this.user = user;
            })
            .catch(error => {
                console.error('Error checking auth:', error);
            });
         }
    },
    created() {
      
      this.getPost()  // Use the id here
      this.getcomments();
      this.getLikes();
      this.increaseViewCount();
      if(this.UserDetails.user.loggedIn){
         this.hasLikedThePost()
      }
      this.currentUrl = window.location.href
  },
  
     watch: {
        '$route'() {
           this.getPost(); // Reload API data when route changes
           this.getcomments();
           this.getLikes();
           this.increaseViewCount()
           if(this.UserDetails.user.loggedIn){
                this.hasLikedThePost()
           }
           this.currentUrl = window.location.href
        },
        'UserDetails.user.loggedIn'(newValue, oldValue) {
               if (newValue) {
                 this.hasLikedThePost()
            } 
            else{
              console.log(oldValue)
            }
          },
       }

      
  };
  </script>
  <style>
  .news-section {
  width: 65%;
  margin: 0 auto;
}

.single-article {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;

}

.single-article img {
  width: 90%;
  height: auto;
  max-height: 480px;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
 /* object-position: center; Centers the image within the container */
}

.single-article .content {
  text-align: justify;
  width: 90%;
  padding-top: 20px;
}

.single-article h1 {
    margin-bottom: 10px;
    color: #d30e15;
    font-family: monospace;
    margin: 0 0 30px;
    font-size: 3.0rem;
    line-height: 40px;

}

.single-article p {
    font-size: 17px;
    color: #040404;
    line-height: 1.6;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.time{
    font-size: 0.9em !important;
}

.comment-form {
    margin-bottom: 1.5rem; /* mb-6 */
}

.comment-container {
    padding: 0.5rem 1rem; /* py-2 px-4 */
    margin-bottom: 1rem; /* mb-4 */
    background-color: #ffffff; /* bg-white */
    border-radius: 0.5rem 0.5rem 0 0; /* rounded-lg rounded-t-lg */
    border: 1px solid #e5e7eb; /* border-gray-200 */
}

.comment-container.dark {
    background-color: #1f2937; /* dark:bg-gray-800 */
    border-color: #374151; /* dark:border-gray-700 */
}

.comment-textarea {
    width: 100%; /* w-full */
    font-size: 0.875rem; /* text-sm */
    color: #1f2937; /* text-gray-900 */
    border: none; /* border-0 */
    outline: none; /* focus:outline-none */
    resize: none; /* Ensure textarea doesn't resize */
}

.likeButton{
  margin-bottom: 10px;
  color:white
}

.comment-textarea::placeholder {
    color: #9ca3af; /* dark:placeholder-gray-400 */
}

.comment-textarea.dark {
    color: #ffffff; /* dark:text-white */
    background-color: #1f2937; /* dark:bg-gray-800 */
}

.comment-submit-button {
    display: inline-flex;
    align-items: center;
    padding: 0.625rem 1rem; /* py-2.5 px-4 */
    font-size: 0.75rem; /* text-xs */
    font-weight: 500; /* font-medium */
    color: #ffffff; /* text-white */
    background-color: #3b82f6; /* bg-primary-700 */
    border-radius: 0.5rem; /* rounded-lg */
    text-align: center;
    border: none;
    cursor: pointer;
}

.comment-submit-button:hover {
    background-color: #2563eb; /* hover:bg-primary-800 */
}

.comment-submit-button:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.2); /* focus:ring-4 focus:ring-primary-200 */
}

.comment-submit-button.dark:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 130, 246, 0.9); /* dark:focus:ring-primary-900 */
}

/* Container for each comment */
/* Container for each comment */
.comment-item {
    margin-bottom: 1rem; /* Space between comments */
    padding: 1rem; /* Inner padding for the comment */
    border-top: 1px solid #e5e7eb; /* Light gray border */
    background-color: #fff; /* White background */
}

/* Comment header containing avatar and username */
.comment-header {
  display: flex; /* Align avatar and text side by side */
  align-items: center; /* Vertically center avatar and comment info */
  margin-bottom: 0.5rem; /* Space between header and comment text */
}

/* Container for the username and created_at */
.comment-info {
  display: flex;
  flex-direction: column; /* Stack the name and created_at vertically */
}

.comment-username {
  font-weight: 600;
  color: #111827;
  transform: translateY(14px);
}

.comment-date {
  font-size: 0.875rem; /* Smaller font size for the date */
  color: #6b7280; /* Lighter gray for the timestamp */
}


/* Commenter avatar (round image) */
.comment-avatar {
    width: 2.5rem; /* 40px width */
    height: 2.5rem; /* 40px height */
    border-radius: 50%; /* Fully rounded */
    margin-right: 0.75rem; /* Space between image and username */
}

/* Username styling */
.comment-username {
    font-weight: 600; /* Bold username */
    color: #111827; /* Dark text for the username */
}

/* Comment text styling */
.comment-text {
    color: #4b5563; /* Gray color for the comment text */
    font-size: 1rem; /* Font size */
    line-height: 1.5; /* Line height for readability */
    margin-top: 0.5rem; /* Space between the header and the comment text */
}

.single-article .meta {
    margin-bottom: 20px;
    color: #1d3245;
    font-size: .95rem;
   
}

.single-article .meta .author {
    display: inline-block;
    margin-right: 10px;
}

.single-article .meta .highlight {
    color: #ff671f;
}

.single-article .meta .author:after {
    content: '|';
    margin-left: 10px;
    display: inline-block;
    color: #ccc;
}

.single-article .meta .post-categories {
    display: inline;
    padding: 0;
    margin: 0;
}

.single-article .meta .post-categories a {
    font-size: .75rem;
    font-weight: 400;
    color: #1d3245;
}

.single-article .meta .post-categories a:first-child:before {
    padding-right: 10px;
}

.single-article .meta .comments-wrapper:before, .single-article .meta .post-categories a:first-child:before {
    display: inline-block;
    content: "|";
    padding-left: 5px;
    color: #ccc;
}

.single-article .meta .comments-wrapper .comments {
    margin-left: 7px;
    font-size: .75rem;
    font-weight: 500;
    color: #1d3245;
}
.description{
  text-align: justify;
}

.comments .section-title {
    margin: 0;
    padding: 0 0 13.33333px;
    display: inline-block;
    color: #00365f;
    font-size: .9375rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
}


 </style>
  