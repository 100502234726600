<template>
  <HeaderComponent ref="header" />
  <router-view />
  <FooterComponent />

</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'App',
  components:{
    HeaderComponent,
    FooterComponent
  },
  methods:{
    handleClickOutside(event) {
      // Check if the click happened outside of the HeaderComponent
      if (this.$refs.header.$el && !this.$refs.header.$el.contains(event.target)) {
        if (this.$refs.header.showDropDown) {
          // Close the dropdown if it's open
          this.$refs.header.showDropDown = false;
        }
      }
    }
  },
  mounted(){
    document.addEventListener('click', this.handleClickOutside);
  }
}
</script>

<style>
.main-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
</style>

