<template>
    <div>
      <h1>Login</h1>
      <form action="#" @submit.prevent="handleLogin">
                <div class="form-row">
                    <input type="email" v-model="formData.email">
                </div>
                <div class="form-row">
                    <input type="password" v-model="formData.password">
                </div>
                <div class="form-row">
                    <button type="submit">Sign In</button>
                </div>
            </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            secrets: [],
            formData: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
      handleLogin() {
    this.$axios.get('/sanctum/csrf-cookie').then(() => {
        this.$axios.post('/api/login', this.formData).then(() => {
            this.user(); // Call your user function after login
        }).catch(error => {
            console.log(error); // Handle error (e.g., credentials didn't match)
        });
    });
},

        user(){
          this.$axios.get('/api/user').then(response => console.log(response.data));
        }
    }
  };
  </script>
  