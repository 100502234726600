<template>
<div class="social-share-container">
           
            <a :href="facebookUrl" class="facebook-btn" target="_blank">
                <img src="@/assets/images/facebook-logo.png"  alt="" />
            </a>
            <a :href="twitterUrl" class="twitter-btn" target="_blank">
                <img src="@/assets/images/twitter-logo.png" alt="" />
            </a>
            <a :href="whatsAppUrl" class="whatsapp-btn" target="_blank">
                <img src="@/assets/images/whatsapp-logo.png" alt="" />
            </a>
            <a :href="telegramUrl" class="telegram-btn" target="_blank">
                <img src="@/assets/images/telegram-logo.png" alt="" />
            </a>
            <a href="#" class="share-btn" target="_blank">
                <img src="@/assets/images/share-icon.png" alt="" />
            </a>
        </div>
</template>

<script>
export default{
    name:'ShareIcons',
    props:['url','title'],
    data(){
        return{
            facebookUrl:'',
            twitterUrl : '',
            whatsAppUrl:'',
            telegramUrl:''
        }
    },
    watch: {
    url(newUrl) {
      this.updateShareUrls(newUrl, this.title);
    },
    title(newTitle) {
      this.updateShareUrls(this.url, newTitle);
    }
  },
  mounted() {
    this.updateShareUrls(this.url, this.title);
  },
  methods: {
    updateShareUrls(url, title) {
      this.facebookUrl = "https://www.facebook.com/sharer.php?u=" + url;
      this.twitterUrl = "https://twitter.com/intent/tweet?url=" + url + "&text=" + title;
      this.whatsAppUrl = "https://api.whatsapp.com/send?text=" + title + ": " + url;
      this.telegramUrl = "https://t.me/share/url?url="+url+"&text="+title
    }
  }
}
</script>

<style>
.social-share-container a {
  text-decoration: none;
  padding: 16px 23px;
}

.social-share-container a img {
  height: 32px;
  transition: all 300ms ease;
}

.social-share-container a:hover img {
  transform: scale(1.2);
}

.social-share-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: 0 4px 40px -8px rgba(0, 0, 0, 0.3);
  border-radius: 0 8px 8px 0;
}
</style>