<template>
      <div class="main-content">
        <NewsSection />
        <SidebarComponent />
      </div>
      <div class="category-content">
        <NewsCategory />
       
      </div>
    
  </template>
  
  <script>
  import NewsSection from '@/components/NewsSection.vue'
  import SidebarComponent from '@/components/SideBar.vue'
  import NewsCategory from '@/components/NewsCateory.vue'
 
  
  export default {
    name: "HomePage",
    components: {
      NewsSection,
      SidebarComponent,
      NewsCategory
    }
  };
  </script>
  
  <style>
  .main-content {
    display: flex;
    justify-content: space-between;
    padding-top: 85px !important;
    padding: 20px;
  }
  .category-content{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    
    
  }
  </style>
  